<template>
    <div>
        <div class="actions__container">
            <div>
                <a
                    href="#"
                    class="btn btn-primary w-40 inline-block mr-1 mb-2"
                    @click.prevent="$router.push({ name: 'userCreate' })"
                >
                    Создать
                </a>
            </div>
        </div>

        <ContentSearch />

        <ContentTable
            :inverse-toggle-icons="true"
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
            @edit="editAction"
            @blocked="blockedAction"
            @delete="deleteAction"
        />

        <PopupDeleteModal
            :is-open="modalOpen"
            :entity-name="modalTitle"
            :modal-data="modalData"
            @update:isOpen="modalOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import ContentTable from '@/components/tables/ContentTable';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentSearch from '../../components/base/ContentSearch';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    components: { ContentSearch, PopupDeleteModal, ContentTable },
    mixins: [errorResponse, pageMixin],
    async beforeRouteUpdate(to, from, next) {
        await this.fetchUsers({ ...to.query });
        next();
    },
    data() {
        return {
            loading: false,
            modalOpen: false,
            modalTitle: null,
            modalData: null,
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'is_blocked', title: 'блокировать', class: 'bg-primary-10', icon: 'toggle', cbName: 'blocked' },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'TrashIcon', cbName: 'delete' },
            ],
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'email', label: 'Логин' },
                { field: 'name', label: 'ФИО' },
                { field: 'role_title', label: 'Роль' },
            ],
            tableData: [],
            pagination: null,
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Пользователи');
        await this.fetchUsers(this.$route.query);
    },
    methods: {
        async fetchUsers(params) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/users', { params });
                this.tableData = data.data;
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch users: ' + ex);
            }
        },
        editAction(row) {
            // this.$router.push({ name: 'userEdit', params: { id: row.id } });
            this._pMTransitionToEditPageWithQuery('userEdit', { id: row.id });
        },
        async blockedAction(row) {
            this.loading = true;

            try {
                const val = !row.is_blocked;
                const blocked = val ? 1 : 0;
                await this.axios.put(`/users/${row.id}/toggle-blocked`, { blocked });
                await this.fetchUsers(this.$route.query);
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant toggle blocked: ' + ex);
            }
        },
        deleteAction(row) {
            this.modalData = row;
            this.modalOpen = true;
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/users/' + data.id)
                .then((res) => {
                    this.fetchUsers(this.$route.query);
                    this.modalOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
};
</script>

<style></style>
